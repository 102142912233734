import * as React from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Feature from "../../components/Feature";
import Activation from "../../components/activation";
import FeaturesSummary from "../../components/productPage/featuresSummary";
import Layout from "../../components/layout";
import { container } from "./charter-base-operations.module.css";
import { SEO } from "../../components/seo";

const CharterBaseOperationsPage = ({ data }) => {
  return (
    <Layout pageTitle="Charter Base Operations">
      <Container fluid className={container}>
        <Feature
          title="Streamlined Efficiency for Charter Base Operations"
          subtitle="Floatist provides real-time updates and communication channels that keep every team member—from dock staff to management—in sync, ensuring smooth operations and quick turnarounds. With Floatist, you can easily monitor and manage all tasks related to boat maintenance, guest check-ins, and logistical coordination directly from a centralized dashboard."
          imageData={data.baseOps.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Yacht statuses and booking stages"
          subtitle="Streamline your charter base operations with Floatist’s booking stages feature, which allows staff to check off completed tasks, ensuring transparency and coordination among team members. This functionality provides a clear visual of progress, allowing everyone involved to see at a glance what has been completed and what tasks remain, enhancing efficiency across all operations."
          imageData={data.statuses.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title="Team Notifications"
          subtitle="Stay informed and proactive with Floatist's notification system, designed to keep your team updated on crucial task progress and changes in real-time. Notifications can be scoped per base or set of yachts so the right people get the right notifications "
          imageData={data.push.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Activity stream"
          subtitle="Enhance team awareness and operational transparency with Floatist's activity stream feature. This dynamic tool provides a real-time feed of all base activities, allowing every team member to stay informed about ongoing operations and updates, ensuring that everyone is aligned and in sync."
          imageData={data.activities.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title="Collect crew list information"
          subtitle="Streamline your pre-charter preparations and allow guests to conveniently submit all necessary crew details ahead of time, ensuring that you have accurate and complete information for safety and regulatory compliance before departure. This proactive approach enhances operational efficiency and provides a smoother start to the charter experience."
          imageData={data.crewlist.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        {/* TODO */}

        {/* Sync with booking aggregators */}

        {/* Eliminate email chains */}

        {/* Ensure paperwork is handled before arrival (contract signatures / deposits?) */}
      </Container>
      <FeaturesSummary />
      <Activation />
    </Layout>
  );
};

export default CharterBaseOperationsPage;

export const Head = () => (
  <SEO
    description="With Floatist, you can easily monitor and manage all tasks
    related to boat maintenance, guest check-ins, and logistical coordination
    directly from a centralized dashboard."
  />
);

export const query = graphql`
  query {
    push: file(relativePath: { eq: "push.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    baseOps: file(relativePath: { eq: "base-ops.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    activities: file(relativePath: { eq: "activities.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    crewlist: file(relativePath: { eq: "crewlist.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    statuses: file(relativePath: { eq: "statuses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
