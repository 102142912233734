import * as React from "react";
import { GatsbyImage, ImageDataLike, getImage } from "gatsby-plugin-image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { row, rowReverse, col, section, text } from "./Feature.module.css";

const Feature = ({
  id,
  title,
  subtitle,
  imageData,
  textSide,
}: {
  id?: string;
  title: string;
  subtitle: string;
  imageData?: ImageDataLike;
  textSide: string;
}) => {
  const image = getImage(imageData ?? null);

  return (
    <>
      <Container fluid className={section} id={id}>
        <Row className={textSide === "right" ? row : rowReverse}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={col}>
            {image ? (
              <GatsbyImage
                image={image}
                alt="connected platform illustration"
                className="img-fluid mx-auto d-block"
                style={{ objectFit: "contain", width: "100%" }}
              />
            ) : null}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={text}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Feature;
